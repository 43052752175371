
.client-card[data-v-a96ecba2] {
    border: 2px solid #33b5e5
}
.client-card .card-header[data-v-a96ecba2] {
    background: rgba(51,181,229,0.25);
    color: rgb(0, 125, 171);
}
.card.client-card .card-body .card-text[data-v-a96ecba2] {
    font-size: 1rem;
}
.client-card-small[data-v-a96ecba2] {
    margin-top: 0px;
}
.card.client-card.client-card-small .card-body .card-text[data-v-a96ecba2] {
    font-size: 0.85rem;
}
.client-card-small .card-body.row[data-v-a96ecba2] {
    padding: .75rem 1.25rem;
}
.client-card-small .card-body .col-md-12[data-v-a96ecba2] {
    padding: 0;
}
.client-card .overlay[data-v-a96ecba2] {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.6);
    background-image: linear-gradient(45deg,rgba(0,0,0,.15) 25%,transparent 25%,transparent 50%,rgba(0,0,0,.15) 50%,rgba(0,0,0,.15) 75%,transparent 75%,transparent);
    background-size: 1.5rem 1.5rem;
    z-index: 1000;
    border-radius: 2px;
}
