
.badge-pill-left[data-v-0a2e11a0] {
    padding-right: 5px;
    border-radius: .6em 0 0 .6em;
    background-image: linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3));
}
.badge-pill-right[data-v-0a2e11a0] {
    padding-left: 5px;
    border-radius: 0 .6em .6em 0;
}
.badge-container[data-v-0a2e11a0] {
    white-space: nowrap;
}
.ai-badge-fixed[data-v-0a2e11a0] {
    background-image: linear-gradient(45deg,rgba(0,0,0,.15) 25%,transparent 25%,transparent 50%,rgba(0,0,0,.15) 50%,rgba(0,0,0,.15) 75%,transparent 75%,transparent);
    background-size: 1rem 1rem;
    color: #ddd !important;
}
